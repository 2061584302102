<template>
    <div class="row">
      <div class="col-md-12">
        <div class="bgc-white bd bdrs-3 p-20 mB-20">
          <h2 class="c-grey-900 mB-20" style="display: inline-block">
            {{ $t("clients") }}
          </h2>
          <span v-if="getRole() != 'owner'">
            <span style="border-right: 1px solid #ddd; margin: 0 10px"></span>
          <button class="btn btn-sm btn-success" @click="addClient">
            <i class="ti-plus mL-5" style="color: #fff"></i>{{ $t('addClient') }}
          </button>
          </span>
          <div id="dataTable_wrapper" class="dataTables_wrapper">
            <form @submit="checkForm">
              <div class="form-group row pB-30 pT-30">
                <div class="col-sm-4">
                  <input
                    type="text"
                    class="form-control"
                    v-model="key"
                    :placeholder="this.$t('clientNameOrMobileNumber')"
                  />
                </div>
                <div v-if="branches.length != 0" class="form-group col-md-3">
              <select id="inputState" class="form-control" v-model="branch_id" :placeholder="$t('branch')">
                <option value="" disabled selected>{{ $t('clientsBranch') }}</option>
                <option  value="">{{ $t('all') }}</option>
                <option  v-for="item in branches" :key="item.id" :value="item.id">{{ getTranslation(item.ar_name , item.en_name) }}</option>
              </select>
            </div>
                <div class="col-sm-2">
                  <button
                    type="submit"
                    class="btn btn-primary mL-10"
                    :disabled="loading"
                  >
                    {{ $t("search") }}
                  </button>
                </div>
              </div>
            </form>
            <table
              id="dataTable"
              class="table table-bordered dataTable table-hover"
              role="grid"
              aria-describedby="dataTable_info"
              style="width: 100%"
              width="100%"
              cellspacing="0"
            >
              <thead>
                <tr role="row">
                  <th
                    class="sorting_asc"
                    tabindex="0"
                    aria-controls="dataTable"
                    rowspan="1"
                    colspan="1"
                    aria-sort="ascending"
                    aria-label="Name: activate to sort column descending"
                  >
                    #
                  </th>
                  <th
                    class="sorting"
                    tabindex="0"
                    aria-controls="dataTable"
                    rowspan="1"
                    colspan="1"
                    aria-label="Position: activate to sort column ascending"
                  >
                    {{ $t("name") }}
                  </th>
                  <th
                    class="sorting"
                    tabindex="0"
                    aria-controls="dataTable"
                    rowspan="1"
                    colspan="1"
                    aria-label="Office: activate to sort column ascending"
                  >
                    {{ $t("mobile_number") }}
                  </th>
                  <th
                    class="sorting"
                    tabindex="0"
                    aria-controls="dataTable"
                    rowspan="1"
                    colspan="1"
                    aria-label="Office: activate to sort column ascending"
                  >
                    {{ $t("enteredBy") }}
                  </th>
                  <th
                    class="sorting"
                    tabindex="0"
                    aria-controls="dataTable"
                    rowspan="1"
                    colspan="1"
                    style="width: 200px"
                    aria-label="Office: activate to sort column ascending"
                  >
                    {{ $t("ordersCount") }}
                  </th>
                  <th
                  v-if="getRole() != 'owner'"
                    class="sorting"
                    tabindex="0"
                    aria-controls="dataTable"
                    rowspan="1"
                    colspan="1"
                    style="width: 30px"
                    aria-label="Start date: activate to sort column ascending"
                  >
                    {{ $t('edit') }}
                  </th>
                </tr>
              </thead>
              <tbody v-show="!loading" v-for="item in clients" :key="item.id">
                <tr role="row" class="odd">
                  <td class="sorting_1">{{ item.id }}</td>
                  <td>
                    {{item.name }}
                  </td>
                  <td style="direction: ltr;">
                    {{ item.mobile_number}}
                  </td>
                  <td>
                    {{item.employee.name}}
                  </td>
                  <td>
                    {{ item.orders_count+' '+$t('order')}}
                  </td>
                  <td v-if="getRole() != 'owner'">
                    <a href="javascript:"
                    @click="editClient(item)"
                      ><span class="badge rounded-pill bgc-amber-50 c-amber-700"
                        ><i class="ti-pencil"></i></span
                    ></a>
                  </td>
                </tr>
              </tbody>
            </table>
            <loading v-show="loading" />
            <p v-show="!clients.length" style="text-align: center">
              {{ $t("noData") }}
            </p>
            <div style="text-align:center;margin-top:40px">
              <pagination style="display:inline-block;text-align:center" v-model="page" :records="pagination.total ?pagination.total : 0 " :per-page="40" @paginate="getClients"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        loading: true,
        statusLoading: true,
        key: "",
        clients: [],
        pagination: {},
        branches: [],
        page:1,
      };
    },
    created() {
      this.getClients();
    },
    methods: {
      getRole() {
      return sessionStorage.getItem('role');
    },
      getTranslation(ar , en) {
        return localStorage.getItem('lang') == 'ar' ? ar : en;
      },
      checkForm: function (e) {
        e.preventDefault();
        this.getClients();
      },
      getClients: function () {
        this.loading = true;
        this.$http
          .get("/backend/clients/get", {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
            params: {
              key : this.key,
            },
          })
          .then(
            function (data) {
              this.loading = false;
              switch (data.status) {
                case 200:
                  this.clients = data.body.data;
                  this.pagination = data.body;
                  console.log(this.pagination);
                  break;
              }
            },
            (err) => {
              this.loading = false;
              switch (err.status) {
                case 500:
                  this.error(this.$t("canNotAccess"));
                  break;
                case 401:
                  this.error(this.$t("verificationProcessError"));
                  localStorage.clear();
                  sessionStorage.clear();
                  this.$router.push("/login");
                  break;
                default:
                  this.error(this.$t("unexpectedError"));
                  break;
              }
            }
          );
      },
      addClient: function () {
        const panel1Handle = this.$showPanel({
          component: () => import("../../views/clients/AddClient.vue"),
          openOn: "right",
          width: 700,
          disableBgClick: true,
          props: {
            reload:this.getClients,
          },
        });
      },
      editClient(client) {
        const panel1Handle = this.$showPanel({
          component: () => import("../../views/clients/EditClient.vue"),
          openOn: "right",
          width: 700,
          disableBgClick: true,
          props: {
            client: client,
            reload:this.getClients,
          },
        });
  
        panel1Handle.promise.then((result) => {});
      },
      error: function (message) {
        this.$toast.error(message, {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
      warning: function (message) {
        this.$toast.warning(message, {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
      success: function (message) {
        this.$toast.success(message, {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
    },
  };
  </script>
  
  <style>
  </style>